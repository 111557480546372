import { Config } from '@backstage/config';
import { UrlPatternDiscovery } from '@backstage/core-app-api';
import { DiscoveryApi } from '@backstage/core-plugin-api';

export class AuthProxyDiscoveryApi implements DiscoveryApi {
  private urlPatternDiscovery: UrlPatternDiscovery;

  constructor(
    baseUrl: string,
    private readonly isAuthProxyingEnabled?: boolean,
    private readonly authProxyUrl?: string,
  ) {
    this.urlPatternDiscovery = UrlPatternDiscovery.compile(
      `${baseUrl}/api/{{ pluginId }}`,
    );
  }

  async getBaseUrl(pluginId: string) {
    if (
      pluginId === 'auth' &&
      this.isAuthProxyingEnabled &&
      this.authProxyUrl
    ) {
      return this.authProxyUrl;
    }

    return this.urlPatternDiscovery.getBaseUrl(pluginId);
  }

  static fromConfig(config: Config) {
    return new AuthProxyDiscoveryApi(
      config.getString('backend.baseUrl'),
      config.getOptionalBoolean('auth.proxy.enabled'),
      config.getOptionalString('auth.proxy.url'),
    );
  }
}
