import React from 'react';
import {
  ClockConfig,
  CustomHomepageGrid,
  HeaderWorldClock,
  HomePageCompanyLogo,
  HomePageRandomJoke,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  WelcomeTitle,
  LayoutConfiguration,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { tools } from './shared';

const clockConfigs: ClockConfig[] = [
  {
    label: 'Malaysia',
    timeZone: 'Asia/Kuala_Lumpur',
  },
  {
    label: 'Sri Lanka',
    timeZone: 'Asia/Colombo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZoneName: 'short',
};

const defaultConfig: LayoutConfiguration[] = [
  {
    component: 'HomePageSearchBar',
    x: 0,
    y: 0,
    width: 24,
    height: 2,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 0,
    y: 1,
    width: 4,
    height: 4,
  },
  {
    component: 'HomePageTopVisited',
    x: 0,
    y: 1,
    width: 4,
    height: 4,
  },
  {
    component: 'HomePageToolkit',
    x: 0,
    y: 2,
    width: 4,
    height: 4,
  },
  {
    component: 'HomePageStarredEntities',
    x: 4,
    y: 0,
    width: 8,
    height: 12,
  },
];

export const HomePage = () => {
  return (
    <Page themeId="home">
      <Header title="Welcome to Product Central!" pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      <Content>
        <CustomHomepageGrid config={defaultConfig}>
          <HomePageSearchBar />
          <HomePageRandomJoke />
          <HomePageStarredEntities />
          <HomePageCompanyLogo />
          <WelcomeTitle />
          <HomePageToolkit tools={tools} />
          <HomePageTopVisited />
          <HomePageRecentlyVisited />
        </CustomHomepageGrid>
      </Content>
    </Page>
  );
};
