import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: 'https://productcentral.io',
    label: 'Homepage',
    icon: <HomeIcon />,
  },
  {
    url: 'https://architecture.99x.io/',
    label: '99x Architecture',
    icon: <ForumIcon />,
  },
  {
    url: 'https://opensource.99x.io/',
    label: '99x Open Source Project',
    icon: <Settings />,
  },
  {
    url: 'https://discord.gg/JkpAwGc4',
    label: 'Discord',
    icon: <ForumIcon />,
  },
];
