import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
// import LayersIcon from '@material-ui/icons/Layers';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import MapIcon from '@material-ui/icons/Map';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import {
  CatalogIcon,
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
} from '@backstage/core-components';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import { SearchModal } from '../search/SearchModal';
import { useApp } from '@backstage/core-plugin-api';
import BuildIcon from '@material-ui/icons/Build';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  sidebarConfig.drawerWidthOpen = 275;
  // const configApi = useApi(configApiRef);

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal>
            {({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}
          </SidebarSearchModal>
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog">
            <SidebarSubmenu title="Catalog">
              <SidebarSubmenuItem
                title="Domains"
                to="catalog?filters[kind]=domain"
                icon={useApp().getSystemIcon('kind:domain')}
              />
              <SidebarSubmenuItem
                title="Systems"
                to="catalog?filters[kind]=system"
                icon={useApp().getSystemIcon('kind:system')}
              />
              <SidebarSubmenuItem
                title="Components"
                to="catalog?filters[kind]=component"
                icon={useApp().getSystemIcon('kind:component')}
              />
              <SidebarSubmenuItem
                title="APIs"
                to="catalog?filters[kind]=api"
                icon={useApp().getSystemIcon('kind:api')}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                title="Resources"
                to="catalog?filters[kind]=resource"
                icon={useApp().getSystemIcon('kind:resource')}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                title="Groups"
                to="catalog?filters[kind]=group"
                icon={useApp().getSystemIcon('kind:group')}
              />
              <SidebarSubmenuItem
                title="Users"
                to="catalog?filters[kind]=user"
                icon={useApp().getSystemIcon('kind:user')}
              />
            </SidebarSubmenu>
          </SidebarItem>
          <MyGroupsSidebarItem
            singularTitle="My Squad"
            pluralTitle="My Squads"
            icon={useApp().getSystemIcon('group')!}
          />
          <SidebarItem
            icon={useApp().getSystemIcon('kind:api')!}
            to="api-docs"
            text="APIs"
          />
          <SidebarItem
            icon={useApp().getSystemIcon('docs')!}
            to="docs"
            text="Docs"
          />
          <SidebarItem icon={PlaylistPlayIcon} to="playlist" text="Playlists" />
          {/* <SidebarItem icon={LayersIcon} to="explore" text="Explore" /> */}
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
          />
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
            <SidebarItem
              icon={MoneyIcon}
              to="cost-insights"
              text="Cost Insights"
            />
          </SidebarScrollWrapper>
          <SidebarDivider />
          <Shortcuts allowExternalLinks />
          <SidebarDivider />
        </SidebarGroup>
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
          <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
