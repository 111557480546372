import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#172B4D',
  };
});

export const pcLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#6F42C1',
        light: '#C5B3E6',
        dark: '#2C1A4D',
      },
      secondary: {
        main: '#FF5630',
        light: '#FFAB00',
        dark: '#6554C0',
      },
      grey: {
        50: '#F8F6FC',
        100: '#E2D9F3',
        200: '#C5B3E6',
        300: '#A98EDA',
        400: '#8C68CD',
        500: '#6F42C1',
        600: '#59359A',
        700: '#432874',
        800: '#2C1A4D',
        900: '#160D27',
      },
      error: {
        main: '#FF5630',
        light: '#FF8F73',
        dark: '#DE350B',
      },
      warning: {
        main: '#FFAB00',
        light: '#FFE380',
        dark: '#FF8B00',
      },
      success: {
        main: '#36B37E',
        light: '#79F2C0',
        dark: '#006644',
      },
      info: {
        main: '#0065FF',
        light: '#4C9AFF',
        dark: '#0747A6',
      },
      navigation: {
        ...palettes.light.navigation,
        background: '#F8F6FC',
        color: '#432874',
        indicator: '#C0A5F0',
        selectedColor: '#432874',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)',
        },
        submenu: {
          background: '#FAF8FE',
          color: '#5A3A94',
          indicator: '#C8B5F4',
          selectedColor: '#5A3A94',
        },
      },
      text: {
        primary: '#172B48',
      },
      background: {
        default: '#FFFFFF',
      },
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 500,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 500,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 500,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 300,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 300,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 300,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    /**
     * Overrides for Backstage components
     */
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundImage: 'unset',
          boxShadow: 'unset',
          paddingBottom: theme.spacing(5),
        }),
        title: ({ theme }) => ({
          color: theme.page.fontColor,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          backgroundImage:
            'linear-gradient(90deg, rgb(30, 4, 76), rgb(89, 53, 154)) !important',
          backgroundColor: '#392467',
          color: '#F8F6FC !important',
          maxHeight: '110px',
          overflow: 'hidden',
        },
      },
    },
  },
});

export const pcDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#C993FF',
        light: '#E9CBFF',
        dark: '#7055A6',
      },
      secondary: {
        main: '#FF8E6E',
        light: '#FFBB9B',
        dark: '#8F6EA1',
      },
      grey: {
        50: '#23222C',
        100: '#36354A',
        200: '#4D4B66',
        300: '#63627F',
        400: '#7A7999',
        500: '#908FAF',
        600: '#A6A5C4',
        700: '#BCBBD9',
        800: '#D1D0EE',
        900: '#E7E6FF',
      },
      error: {
        main: '#FF8E6E',
        light: '#FFB39D',
        dark: '#9F4C3D',
      },
      warning: {
        main: '#FFBB9B',
        light: '#FFD2C4',
        dark: '#A17D64',
      },
      success: {
        main: '#5BD08B',
        light: '#8FE1B3',
        dark: '#217E3F',
      },
      info: {
        main: '#4DA3FF',
        light: '#8BC4FF',
        dark: '#1A64CC',
      },
      navigation: {
        ...palettes.dark.navigation,
        background: '#23222C',
        color: '#BCBBD9',
        indicator: '#908FAF',
        selectedColor: '#D1D0EE',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)',
        },
        submenu: {
          background: '#2D2C36',
          color: '#CFCFD9',
          indicator: '#A0A0BF',
          selectedColor: '#E1E0EE',
        },
      },
      text: {
        primary: '#FFFFFF',
      },
      background: {
        default: '#18171F',
      },
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 500,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 500,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 500,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 300,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 300,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 300,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundImage: 'unset',
          boxShadow: 'unset',
          paddingBottom: theme.spacing(5),
        }),
        title: {
          color: '#FFFFFF',
        },
        subtitle: {
          color: '#FFFFFF',
        },
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          backgroundImage:
            'linear-gradient(90deg, rgb(30, 4, 76), rgb(89, 53, 154)) !important',
          backgroundColor: '#392467',
          color: '#F8F6FC !important',
          maxHeight: '110px',
          overflow: 'hidden',
        },
      },
    },
  },
});
